import { lazy } from "react";

import Catalogue from "../components/pages/Catalogue/Catalogue";
import Home from "../components/pages/Home/Home";
import Basket from "../components/pages/Basket/Basket";
import Details from "../components/pages/Details/Details";
import Checkout from "../components/pages/Checkout/Checkout";
import Payment from "../components/pages/Payment/Payment";
// import Order from "../components/pages/Order/Order";
import Search from "../components/pages/Search/Search";
// import Distributors from "../components/pages/Distributors/Distributors";

// import Features from "../components/pages/Features/Features";

// import About from "../components/pages/About/About";
// import Kits from "../components/pages/Kits/Kits";
// import Privacy from "../components/pages/Privacy/Privacy";
// import CookiesPolicy from "../components/pages/CookiesPolicy/CookiesPolicy";
// import Terms from "../components/pages/Terms/Terms";

// import Contact from "../components/pages/Contact/Contact";
// import Faq from "../components/pages/Faq/Faq";
// import Returns from "../components/pages/Returns/Returns";
// import Warranties from "../components/pages/Warranties/Warranties";
// import Shipping from "../components/pages/Shipping/Shipping";

import { popularRoutes } from "./popularRoutes";

const LazyOrder = lazy(() => import("../components/pages/Order/Order"));
const LazyDistributors = lazy(() =>
  import("../components/pages/Distributors/Distributors")
);
const LazyFeatures = lazy(() =>
  import("../components/pages/Features/Features")
);

const LazyAbout = lazy(() => import("../components/pages/About/About"));
const LazyKits = lazy(() => import("../components/pages/Kits/Kits"));
const LazyPrivacy = lazy(() => import("../components/pages/Privacy/Privacy"));
const LazyCookiePolicy = lazy(() =>
  import("./../components/pages/CookiesPolicy/CookiesPolicy")
);
const LazyTerms = lazy(() => import("./../components/pages/Terms/Terms"));

const LazyContact = lazy(() => import("./../components/pages/Contact/Contact"));
const LazyFaq = lazy(() => import("./../components/pages/Faq/Faq"));
const LazyReturns = lazy(() => import("./../components/pages/Returns/Returns"));
const LazyWarranties = lazy(() =>
  import("./../components/pages/Warranties/Warranties")
);
const LazyShipping = lazy(() =>
  import("./../components/pages/Shipping/Shipping")
);

const routes = [
  {
    path: "/",
    component: Home,
    exact: true,
  },
  {
    path: "/features",
    component: LazyFeatures,
  },
  {
    path: "/faq",
    component: LazyFaq,
  },
  {
    path: "/returns",
    component: LazyReturns,
  },
  {
    path: "/terms",
    component: LazyTerms,
  },
  {
    path: "/kits",
    component: LazyKits,
  },
  {
    path: "/warranties",
    component: LazyWarranties,
  },
  {
    path: "/distributors",
    component: LazyDistributors,
  },
  {
    path: "/basket",
    component: Basket,
  },
  {
    path: "/privacy",
    component: LazyPrivacy,
  },
  {
    path: "/cookies",
    component: LazyCookiePolicy,
  },
  {
    path: "/shipping",
    component: LazyShipping,
  },
  {
    path: "/about",
    component: LazyAbout,
  },
  {
    path: "/contact",
    component: LazyContact,
  },
  {
    path: "/checkout",
    component: Checkout,
  },
  {
    path: "/payment",
    component: Payment,
  },
  {
    path: "/order/:id",
    component: LazyOrder,
  },
  {
    path: "/search/:term",
    component: Search,
  },
  {
    path: "/details/:name",
    component: Details,
  },
  {
    path: "/parts/:make?/:model?/:engine?",
    component: Catalogue,
  },
  ...popularRoutes,
];

export default routes;
