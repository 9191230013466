import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { withRouter } from "react-router";
import { Container } from "react-bootstrap";
import DetailsPhoto from "../../organisms/DetailsPhoto/DetailsPhoto";
import DetailsServices from "../../organisms/DetailsServices/DetailsServices";
import ProductDetails from "../../organisms/ProductDetails/ProductDetails";
import DetailsSwitch from "../../organisms/DetailsSwitch/DetailsSwitch";
import Loader from "../../molecules/Loader/Loader";
import UniversalModal from "../../organisms/UniversalModal/UniversalModal";
import DetailsAlert from "../../molecules/DetailsAlert/DetailsAlert";
import useItemDetails from "../../../hooks/useItemDetails";
import useBasketActions from "../../../hooks/useBasketActions";
import Enquiry from "../../organisms/forms/Enquiry/Enquiry";

const Details = ({ match }) => {
  const [{ part, error }, setRecord, setError] = useItemDetails();
  const {
    addHandler,
    showAlert,
    buttonText,
    error: buttonError,
    setError: setButtonError,
  } = useBasketActions();
  const [showEnquiry, setShowEnquiry] = useState(false);

  const enquiryCloseHandler = () => setShowEnquiry(false);
  const enquiryHandler = () => setShowEnquiry(true);
  const modalClickHandler = () => {
    setError("");
    setButtonError("");
  };

  useEffect(() => setRecord(match.params.name), [match.params.name]);

  return (
    <section>
      <Container className="gtm_product_page">
        {part ? (
          <StyledContentWrapper>
            <StyledContent>
              {showAlert && <DetailsAlert part={part} />}
              <UpperPart>
                <DetailsPhoto photos={part.photos || []} name={part.name} />
                <ProductDetails
                  part={part}
                  addHandler={addHandler}
                  enquiryHandler={enquiryHandler}
                  buttonText={buttonText}
                />
              </UpperPart>
              <DetailsSwitch part={part} />
            </StyledContent>
            <DetailsServices />
          </StyledContentWrapper>
        ) : (
          <Loader show={error ? false : true} />
        )}
      </Container>
      {(error && <StyledErrorMsg>{error}</StyledErrorMsg>) ||
        (buttonError && (
          <UniversalModal
            text={error || buttonError}
            clickHandler={modalClickHandler}
          />
        ))}
      <Enquiry
        show={showEnquiry}
        handleClose={enquiryCloseHandler}
        part={part}
      />
    </section>
  );
};

export default withRouter(Details);

const StyledContentWrapper = styled.section`
  display: grid;
  column-gap: 30px;
  padding: 30px 0;
  min-height: 500px;

  @media screen and (min-width: 1000px) {
    grid-template-columns: 9fr 2fr;
  }
`;

const UpperPart = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  @media screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
    column-gap: 30px;
  }
`;

const StyledContent = styled.div``;

const StyledErrorMsg = styled.div`
  margin: 50px auto;
  max-width: 600px;
  width: 60%;
  text-align: center;
  font-weight: bold;
  padding: 20px;
  border: 1px solid black;
`;
