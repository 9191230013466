import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useAppContext } from "../../../context/AppContext";

import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { COOKIESNAME } from "./../../../vars/vars";
import "./CookieManager.scss";

const CookieSettingsDialog = ({ onSavePreferences }) => {
  const { showCookiesManager, setShowCookiesManager } = useAppContext();
  const [cookiePreferences, setCookiePreferences] = useState([
    { label: "Mandatory", enabled: true, mandatory: true },
    { label: "Functional", enabled: false, mandatory: false },
    { label: "Analytics", enabled: false, mandatory: false },
    { label: "Advertisement", enabled: false, mandatory: false },
  ]);
  const [show, setShow] = useState(false);

  const acceptAll = () => {
    const aceptedAll = cookiePreferences.map((obj) => ({
      ...obj,
      enabled: true,
    }));

    setCookiePreferences(aceptedAll);
    emitPreferences(aceptedAll);
  };

  const getCookies = (name) => {
    const consents = Cookies.get(name);
    return consents ? JSON.parse(consents) : null;
  };

  const emitPreferences = (preferences) => {
    const currentPreferences = preferences.map((pref) => ({
      label: pref.label,
      enabled: pref.enabled,
    }));

    onSavePreferences(currentPreferences);
    handleClose();
  };

  const saveAndClose = () => {
    emitPreferences(cookiePreferences);
  };

  useEffect(() => {
    updatePreferencesFromCookies();
  }, []);

  const updatePreferencesFromCookies = () => {
    const cookies = getCookies(COOKIESNAME);
    if (cookies && cookies.accepted) {
      updateCookiePreferences(cookies.preferences);
    }
  };

  const updateCookiePreferences = (data) => {
    setCookiePreferences((prev) =>
      prev.map((pref) => ({
        ...pref,
        enabled: pref.mandatory || data.includes(pref.label),
      }))
    );
  };
  const handleClose = () => {
    setShow(false);
  };
  const handlePreferenceChange = (index) => {
    setCookiePreferences((prev) =>
      prev.map((pref, i) =>
        i === index ? { ...pref, enabled: !pref.enabled } : pref
      )
    );
  };

  useEffect(() => {
    setShow(showCookiesManager);
    setShowCookiesManager(showCookiesManager);
    updatePreferencesFromCookies();
  }, [showCookiesManager]);

  return (
    <Modal show={show} size="xs" centered animation onHide={handleClose}>
      <Modal.Header className="customTheme">
        <Modal.Title>Cookie Settings</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <h5 className="font-weight-bold mb-3">Your privacy</h5>
        <p className="mb-3">
          Below you can adjust the cookie settings for both our and our
          partners' cookies. We need your consent to use analytical and
          marketing cookies.
        </p>
        <p className="mb-3">
          If you want to accept all cookies, click 'Accept all'. If you prefer
          that we only use necessary cookies, select 'Save settings and close'.
          To change your cookie preferences, move the slider next to the
          appropriate category. You have the right to view and modify your
          settings at any time.
        </p>
        <p className="mb-3">
          Using cookies involves processing your personal data related to
          activity on the website. Detailed information about how we and our
          partners use cookies and process your data, as well as information
          about your rights, can be found in our{" "}
          <Link to="/privacy" target="_blank" className="customLinkColor">
            Privacy Policy.
          </Link>
        </p>
        <h5 className="font-weight-bold mb-3">Manage cookies</h5>
        <Form>
          {cookiePreferences.map((preference, index) => (
            <Form.Group
              key={index}
              className="d-flex justify-content-between align-items-center mb-2"
            >
              <Form.Check
                type="switch"
                id={`custom-switch-${index}`}
                label={preference.label}
                checked={preference.enabled}
                onChange={() => handlePreferenceChange(index)}
                disabled={preference.mandatory}
                className="custom-switch"
              />
            </Form.Group>
          ))}
        </Form>
      </Modal.Body>
      <Modal.Footer className="d-flex flex-column">
        <Button variant="danger" onClick={() => acceptAll()} className="w-100">
          ACCEPT ALL
        </Button>
        <Button variant="secondary" onClick={saveAndClose} className="w-100">
          SAVE SETTINGS AND CLOSE
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CookieSettingsDialog;
