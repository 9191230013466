import React from "react";
import styled from "styled-components";
import { PRIMARY } from "../../../vars/vars";

const PartFinderButton = ({ text, submitHandler, disabled }) => (
  <StyledPartFinderButton onClick={submitHandler} disabled={disabled}>
    {text}
  </StyledPartFinderButton>
);

export default PartFinderButton;

const StyledPartFinderButton = styled.button`
  font-weight: 400;
  padding: 0 20px;
  height: 35px;
  color: white;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  text-transform: uppercase;
  border: none;
  position: relative;
  margin: 20px 0;
  margin-left: 10px;
  background-color: ${(props) => (props.disabled ? "#444" : PRIMARY)};
  cursor: ${(props) => (props.disabled ? null : "pointer")};

  &:after {
    content: "";
    width: 30px;
    height: 100%;
    display: inline-block;
    position: absolute;
    top: 0;
    right: -29px;
    clip-path: polygon(0 0, 0% 100%, 100% 50%);
    background-color: ${(props) => (props.disabled ? "#444" : PRIMARY)};
    cursor: ${(props) => (props.disabled ? null : "pointer")};
  }
`;
