import React, { useEffect } from "react";
import styled from "styled-components";
import { Alert } from "react-bootstrap";
import { useAppContext } from "../../../context/AppContext";

const ukCountries = ["GB"];

const CountryAlert = ({ full }) => {
  const { userCountry } = useAppContext();
  let linkToDisplay;

  useEffect(() => {
    if (userCountry) {
      setLinkToDisplay(userCountry.code);
    }
  }, [userCountry]);

  function setLinkToDisplay(code) {
    linkToDisplay = ukCountries.includes(code)
      ? "pedders.co.uk"
      : code === "FR"
      ? "https://www.trans4x4.com/"
      : "";
  }

  return linkToDisplay ? (
    <AlertWrapper full={full}>
      <Alert variant={"warning"}>
        Hello! It seems that you are coming from {userCountry.name}. Just so you
        know, you can browse the website but we would like to ask you to place
        your order at{" "}
        <a
          rel={"noopener noreferrer nofollow"}
          target={"_blank"}
          href={"https://" + linkToDisplay}
        >
          {linkToDisplay}
        </a>
        .
      </Alert>
    </AlertWrapper>
  ) : null;
};

export default CountryAlert;

const AlertWrapper = styled.div`
  padding: ${(props) => (props.full ? "0" : "0 20px")};
`;
