import React from "react";
import styled from "styled-components";

const DetailsHeader = ({ text }) => (
  <StyledDetailsHeader>{text}</StyledDetailsHeader>
);

export default DetailsHeader;

const StyledDetailsHeader = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: #444;
`;
