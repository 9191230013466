import React from "react";
import styles from "./Category.module.scss";

const Category = ({ name, children }) => (
  <div className={styles.Category}>
    <div className={styles.Header}>{name}</div>
    {children}
  </div>
);

export default Category;
