import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import UnderlinedHeader from "../../molecules/UnderlinedHeader/UnderlinedHeader";
import styled from "styled-components";

const Youtube = () => {
  useEffect(() => {
    const vidDefer = document.getElementsByTagName("iframe");
    for (let i = 0; i < vidDefer.length; i++) {
      if (vidDefer[i].getAttribute("data-src")) {
        vidDefer[i].setAttribute("src", vidDefer[i].getAttribute("data-src"));
      }
    }
  }, []);

  return (
    <StyledWrapper>
      <Container>
        <StyledYoutube>
          <div>
            <lite-youtube
              videoId="BVBSDzjTnHw"
              params="controls=1"
              title={"Driving test: Pedders explain the effects of weight"}
              width="100%"
              height="315"
              allowFullScreen
            />
          </div>
          <div>
            <UnderlinedHeader
              text={"Driving test: Pedders explain the effects of weight"}
            />
            <p>
              At Sandown International Raceway, Pedders Suspension & Brakes put
              a range of vehicles through a series of driving tests to highlight
              the effects of weight on ride, handling and braking.
            </p>
          </div>
        </StyledYoutube>
        <StyledYoutube>
          <div>
            <UnderlinedHeader text={"SportsRyder & TrakRyder Coils Springs"} />
            <p>
              If you are looking for that great lowered look for your sports or
              performance vehicle or you wanna enhance your 4 wheel drive,
              Pedders have a massive range of SportsRyder and TrakRider premium
              coil springs for just about every mission imaginable on wheels.
              SportsRyder coil springs are unrivaled for build quality,
              durability, handling and vehicle appearance.
            </p>
          </div>
          <div>
            <lite-youtube
              videoId="g1aSCo4S2_E"
              params="controls=1"
              title={"SportsRyder & TrakRyder Coils Springs"}
              width="100%"
              height="315"
              allowFullScreen
            />
          </div>
        </StyledYoutube>
        <StyledYoutube>
          <div>
            <lite-youtube
              videoId="-x9r6VqbGrg"
              params="controls=1"
              title={
                "TrakRyder Foam Cell 4x4 Shock Absorbers & Nitrogen Gas Shocks"
              }
              width="100%"
              height="315"
              allowFullScreen
            />
          </div>
          <div>
            <UnderlinedHeader
              text={
                "TrakRyder Foam Cell 4x4 Shock Absorbers & Nitrogen Gas Shocks"
              }
            />
            <p>
              In this video we ask Neil Crompton to discuss the key difference
              in our range of 4WD, Pick Up Truck and SUV vehicle shock absorbers
              from our TrakRyder range.
            </p>
          </div>
        </StyledYoutube>
      </Container>
    </StyledWrapper>
  );
};

export default Youtube;

const StyledWrapper = styled.div`
  margin: 20px 0;
`;

const StyledYoutube = styled.div`
  display: grid;
  margin-top: 20px;

  @media screen and (min-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
  }
`;
