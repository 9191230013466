import React from "react";
import styled from "styled-components";
import styles from "./DetailsInputButtonInBasket.module.scss";
import { PRIMARY } from "../../../vars/vars";

const DetailsInputButtonInBasket = ({ clickHandler, image, name }) => (
  <StyledButton className={styles[name]} onClick={(e) => clickHandler(name, e)}>
    <Image src={image} />
  </StyledButton>
);

export default DetailsInputButtonInBasket;

const Image = styled.img`
  width: 8px;
  height: 8px;
`;

const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 20px;
  background: #666666;
  border: none;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.1s ease;
  padding: 0;

  &:hover {
    cursor: pointer;
    background-color: ${PRIMARY};
  }
`;
