import React from "react";
import styled from "styled-components";
import { PRIMARY } from "../../../vars/vars";

const Line = ({ bolder }) => <StyledLine bolder={bolder} />;

export default Line;

const StyledLine = styled.div`
  margin-top: 10px;
  width: 40px;
  height: ${(props) => (props.bolder ? "3px" : "2px")};
  background-color: ${PRIMARY};
`;
