import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { PRIMARY } from "../../../vars/vars";
import placeholderWebp from "../../../assets/placeholders/pedders_placeholder.webp";
import placeholder from "../../../assets/placeholders/pedders_placeholder.jpg";

const OrderLine = ({ item, currency }) => {
  return (
    <>
      <DescriptionWrapper>
        <Photo to={`/details/${item.item.urlcomponent}`}>
          {item.item.photos.length ? (
            <img src={item.item.photos[0].url} alt={item.displayname} />
          ) : (
            <picture>
              <source srcSet={placeholderWebp} type="image/webp" />
              <img src={placeholder} alt={item.displayname} />
            </picture>
          )}
        </Photo>
        <div>
          <Description to={`/details/${item.item.urlcomponent}`}>
            {item.item.description}
            <br />
            {item.displayname}
          </Description>
        </div>
      </DescriptionWrapper>
      <Price>
        <span>Price:</span>
        {item.grossprice || item.price} {currency}
      </Price>
      {<Price>{item.quantity}</Price>}
      <Price>
        <span>Subtotal:</span>
        {item.amount || item.grosamount} {currency}
      </Price>
    </>
  );
};

export default OrderLine;

const Price = styled.div`
  grid-column: 1 / 5;
  display: flex;
  justify-content: space-between;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  color: #666666;
  font-size: 16px;
  vertical-align: top;
  padding: 10px 8px 0;
  text-align: right;

  @media (min-width: 767px) {
    padding: 10px 0px 0;
    grid-column: auto;
    display: block;
    font-size: 17px;

    span {
      display: none;
    }
  }
`;

const Description = styled(Link)`
  flex-basis: 60%;
  margin-bottom: 16px;
  display: inline-block;
  font-size: 14px;
  width: 100%;
  text-align: center;
  color: #444444;
  font-family: "Open Sans", sans-serif;
  &:hover {
    color: ${PRIMARY};
  }

  @media (min-width: 767px) {
    text-align: left;
    font-size: 15px;
  }
`;

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 1 / 5;
  gap: 5px;
  font-size: 12px;
  padding: 10px 0;

  @media (min-width: 767px) {
    flex-direction: row;
    grid-column: auto;
  }
`;

const Photo = styled(Link)`
  img {
    width: 100%;
    height: 150px;
    object-fit: contain;
    border: 1px solid #ddd;
    &:hover {
      border-color: ${PRIMARY};
    }
  }

  @media (min-width: 767px) {
    img {
      width: 150px;
      margin-right: 10px;
    }
  }
`;
