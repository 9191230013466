import React, { useEffect, useState } from "react";
import styles from "./Marquee.module.scss";
import axios from "axios";
import { useAppContext } from "./../../../context/AppContext";

function Marquee() {
  const [text, setText] = useState("");
  const [marqueeStyle, setMarqueeStyle] = useState({ display: "none" });
  const { initialAction } = useAppContext();

  const api_url = "https://api.ozparts.eu/marquees/pedders";

  async function catchText(url) {
    try {
      const res = await axios.get(url);
      if (res.data.show) {
        setText(res.data.text_en);
        setMarqueeStyle({ display: "block" });
      } else {
        setMarqueeStyle({ display: "none" });
      }
    } catch {}
  }

  useEffect(() => {
    if (initialAction) {
      catchText(api_url);
    }
  }, [initialAction]);

  return (
    <div className={styles.scrollContainer} style={marqueeStyle}>
      <div className={styles.scrollText}>{text}</div>
    </div>
  );
}

export default Marquee;
