import React from "react";
import { Link } from "react-router-dom";
import "./DesktopNavLink.scss";

const DesktopNavLink = ({ to, display, children }) => {
  return to ? (
    <Link
      className={`${"DesktopNavLink"} ${
        to === "/distributors" && "gtm_btn_distributors"
      }`}
      to={to}
    >
      {display}
    </Link>
  ) : (
    <>
      <div className={"DesktopNavLink"}>{display}</div>
      {children}
    </>
  );
};

export default DesktopNavLink;
