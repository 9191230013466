import React from "react";
import styled from "styled-components";

const CheckoutHeader = ({ text }) => {
  return <StyledComponent>{text}</StyledComponent>;
};

export default CheckoutHeader;

const StyledComponent = styled.div`
  font-family: "Open Sans", sans-serif;
  font-size: 26px;
  color: #333333;
  font-weight: 300;
  max-width: 600px;
  border-bottom: 1px solid #ccc;
  margin-top: 20px;
  padding-bottom: 10px;
`;
