const links = [
  {
    header: "4X4 SPECIALIST KITS",
    special: true,
    links: [
      {
        to: "/info-mitsubishi-pajero",
        display: "Mitsubishi Pajero",
        specialKits: true,
      },
      // { to: "/", display: "Mitsubishi Shogun" },
      // { to: "/", display: "Nissan Patrol" },
      {
        to: "/info-nissan-navara",
        display: "Nissan Navara",
        specialKits: true,
      },
      {
        to: "/info-nissan-x-trail",
        display: "Nissan X-Trail",
        specialKits: true,
      },
      {
        to: "/info-subaru-forester",
        display: "Subaru Forester",
        specialKits: true,
      },
      // { to: "/", display: "Subaru Outback" },
      // { to: "/", display: "Toyota Hilux 4WD" },
      {
        to: "/info-toyota-landcruiser",
        display: "Toyota Landcruiser",
        specialKits: true,
      },
      // { to: "/", display: "Volkswagen Amarok" },
    ],
  },
  {
    header: "HOW CAN WE HELP",
    links: [
      { to: "/contact", display: "Contact Us" },
      { to: "/shipping", display: "Shipping Conditions" },
      { to: "/returns", display: "Returns" },
      { to: "/warranties", display: "Warranties" },
      { to: "/faq", display: "FAQ" },
    ],
  },
  {
    header: "FURTHER INFORMATION",
    links: [
      { to: "/about", display: "About Us" },
      { to: "/kits", display: "4x4 Specialist Kits" },
      { to: "/privacy", display: "Privacy Policy" },
      { to: "/cookies", display: "Cookies Policy" },
      { to: "#", display: "Cookies Manager" },
      { to: "/terms", display: "Terms & Conditions" },
    ],
  },
  // {
  //   header: "OTHER KITS",
  //   links: [
  //     { to: "/", display: "Chrysler 300C" },
  //     { to: "/", display: "Volkswagen Transporter T5" },
  //   ],
  // },
];

export default links;
