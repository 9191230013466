import { lazy } from "react";

const lazySubaruForester = lazy(() =>
  import("../components/pages/popular/SubaruForester/SubaruForester")
);
const lazyNissanNavara = lazy(() =>
  import("../components/pages/popular/NissanNavara/NissanNavara")
);
const lazyMitsubishiPajero = lazy(() =>
  import("../components/pages/popular/MitsubishiPajero/MitsubishiPajero")
);
const lazyToyotaLandcruiser = lazy(() =>
  import("../components/pages/popular/Toyota Landcruiser/Toyota Landcruiser")
);
const lazyNissanXTrail = lazy(() =>
  import("../components/pages/popular/NissanXTrail/NissanXTrail")
);
const lazySantaFe = lazy(() =>
  import("../components/pages/popular/SantaFe/SantaFe")
);
const lazyNissanPatrol = lazy(() =>
  import("../components/pages/popular/NissanPatrol/NissanPatrol")
);
const lazyNissanPathfinder = lazy(() =>
  import("../components/pages/popular/NissanPathfinder/NissanPathfinder")
);
const lazyMitsubishiL200 = lazy(() =>
  import("../components/pages/popular/MitsubishiL200/MitsubishiL200")
);
const lazyChrysler300C = lazy(() =>
  import("../components/pages/popular/Chrysler300C/Chrysler300C")
);
const lazyDaciaDuster = lazy(() =>
  import("../components/pages/popular/DaciaDuster/DaciaDuster")
);
const lazyFordMustang = lazy(() =>
  import("../components/pages/popular/FordMustang/FordMustang")
);
const lazyFordRanger = lazy(() =>
  import("../components/pages/popular/FordRanger/FordRanger")
);
const lazyIsuzuDMax = lazy(() =>
  import("../components/pages/popular/IsuzuDMax/IsuzuDMax")
);
const lazyJeepWrangler = lazy(() =>
  import("../components/pages/popular/JeepWrangler/JeepWrangler")
);
const lazyKiaSorento = lazy(() =>
  import("../components/pages/popular/KiaSorento/KiaSorento")
);
const lazyMercedesXClass = lazy(() =>
  import("../components/pages/popular/MercedesXClass/MercedesXClass")
);
const lazyMazdaMX5 = lazy(() =>
  import("../components/pages/popular/MazdaMX5/MazdaMX5")
);
const lazyMitsubishiShogun = lazy(() =>
  import("../components/pages/popular/MitsubishiShogun/MitsubishiShogun")
);
const lazySubaruImpreza = lazy(() =>
  import("../components/pages/popular/SubaruImpreza/SubaruImpreza")
);
const lazySubaruOutback = lazy(() =>
  import("../components/pages/popular/SubaruOutback/SubaruOutback")
);
const lazySubaruBRZ = lazy(() =>
  import("../components/pages/popular/SubaruBRZ/SubaruBRZ")
);
const lazyToyotaHilux = lazy(() =>
  import("../components/pages/popular/ToyotaHilux/ToyotaHilux")
);
const lazyVWAmarok = lazy(() =>
  import("../components/pages/popular/VWAmarok/VWAmarok")
);
const lazyVWTransporter = lazy(() =>
  import("../components/pages/popular/VWTransporter/VWTransporter")
);
const lazyVWGolf = lazy(() =>
  import("../components/pages/popular/VWGolf/VWGolf")
);
const lazyToyotaGT = lazy(() =>
  import("../components/pages/popular/ToyotaGT/ToyotaGT")
);

export const popularRoutes = [
  {
    path: "/info-toyota-gt86",
    component: lazyToyotaGT,
  },
  {
    path: "/info-volkswagen-golf",
    component: lazyVWGolf,
  },
  {
    path: "/info-volkswagen-transporter",
    component: lazyVWTransporter,
  },
  {
    path: "/info-volkswagen-amarok",
    component: lazyVWAmarok,
  },
  {
    path: "/info-toyota-hilux",
    component: lazyToyotaHilux,
  },
  {
    path: "/info-subaru-brz",
    component: lazySubaruBRZ,
  },
  {
    path: "/info-subaru-outback",
    component: lazySubaruOutback,
  },
  {
    path: "/info-subaru-impreza",
    component: lazySubaruImpreza,
  },
  {
    path: "/info-mitsubishi-shogun",
    component: lazyMitsubishiShogun,
  },
  {
    path: "/info-mazda-mx5",
    component: lazyMazdaMX5,
  },
  {
    path: "/info-mercedes-x-class",
    component: lazyMercedesXClass,
  },
  {
    path: "/info-kia-sorento",
    component: lazyKiaSorento,
  },
  {
    path: "/info-jeep-wrangler",
    component: lazyJeepWrangler,
  },
  {
    path: "/info-isuzu-d-max",
    component: lazyIsuzuDMax,
  },
  {
    path: "/info-ford-ranger",
    component: lazyFordRanger,
  },
  {
    path: "/info-ford-mustang",
    component: lazyFordMustang,
  },
  {
    path: "/info-dacia-duster",
    component: lazyDaciaDuster,
  },
  {
    path: "/info-chrysler-300c",
    component: lazyChrysler300C,
  },
  {
    path: "/info-subaru-forester",
    component: lazySubaruForester,
  },
  {
    path: "/info-nissan-navara",
    component: lazyNissanNavara,
  },
  {
    path: "/info-mitsubishi-pajero",
    component: lazyMitsubishiPajero,
  },
  {
    path: "/info-mitsubishi-l200",
    component: lazyMitsubishiL200,
  },
  {
    path: "/info-toyota-landcruiser",
    component: lazyToyotaLandcruiser,
  },
  {
    path: "/info-nissan-pathfinder",
    component: lazyNissanPathfinder,
  },
  {
    path: "/info-nissan-x-trail",
    component: lazyNissanXTrail,
  },
  {
    path: "/info-hyundai-santa-fe",
    component: lazySantaFe,
  },
  {
    path: "/info-nissan-patrol",
    component: lazyNissanPatrol,
  },
];
