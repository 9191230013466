import React from "react";
import styled from "styled-components";
import { PRIMARY } from "../../../vars/vars";

const FormLabel = ({ text, required, htmlFor }) => (
  <StyledLabel htmlFor={htmlFor} required={required}>
    {text}
  </StyledLabel>
);

export default FormLabel;

const StyledLabel = styled.label`
  margin: 20px 0 8px;
  color: #666666;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  display: block;

  &:after {
    content: "*";
    color: ${PRIMARY};
    display: ${(props) => (props.required ? "inline-block" : "none")};
  }
`;
