import React from "react";
import Vehicles from "../../molecules/dropdowns/Vehicles/Vehicles";

export const links = [
  { to: "/", display: "home" },
  { to: "/features", display: "product features" },
  {
    display: "where to buy",
    to: "/distributors",
  },
  {
    display: "popular vehicles",
    to: "/",
    children: <Vehicles />,
  },
];
