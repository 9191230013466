import React from "react";
import styled from "styled-components";
import { deliveryTime } from "../../../vars/vars";

const AirFreight = () => (
  <>
    <Header>
      <i
        className="fa fa-check-square"
        style={{ color: "#50be19" }}
        aria-hidden="true"
      ></i>
      <StyledStock>Available</StyledStock>
    </Header>
    <Header>
      <StyledAvl>{deliveryTime.IN_MANUFACTURER}</StyledAvl>
    </Header>
  </>
);

export default AirFreight;

const Header = styled.div`
  display: flex;
  font-size: 22px;
  align-items: center;
`;

const StyledStock = styled.div`
  color: #50be19;
  margin-left: 5px;
  font-weight: 700;
  font-family: "Open Sans", sans-serif;
`;

const StyledAvl = styled.div`
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: rgb(102, 102, 102);
`;
