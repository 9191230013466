import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { PRIMARY } from "../../../vars/vars";

const DetailsRelatedItem = ({
  displayname,
  photos,
  name,
  description,
  price,
}) => (
  <RelatedItem>
    <Photo
      src={
        photos.length
          ? photos[0].url
          : "https://3cerp.eu/api/file/pedders-logo.png"
      }
      alt={displayname}
    />
    <StyledLink to={"/details/" + name}>
      {description} {name}
    </StyledLink>
    <Price>
      {price.grossprice} {price.symbol}
    </Price>
  </RelatedItem>
);

export default DetailsRelatedItem;

const RelatedItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #ddd;
`;

const Photo = styled.img`
  width: 250px;
  height: 250px;
  object-fit: contain;
`;

const StyledLink = styled(Link)`
  color: #444444;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  text-align: center;
  padding: 0 10px;

  &:hover {
    color: ${PRIMARY};
  }
`;

const Price = styled.div`
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  color: ${PRIMARY};
  font-size: 24px;
  margin: 5px 0 10px 0;
`;
