import React from "react";
import styled from "styled-components";
import { PRIMARY } from "../../../vars/vars";
import { Link } from "react-router-dom";

const MiniBasketButton = ({ children, to }) => (
  <StyledButton
    to={to}
    className={`${children === "checkout" && "gtm_btn_checkout"} ${
      children === "view cart" && "gtm_btn_viewcart"
    }`}
  >
    {children}
  </StyledButton>
);

export default MiniBasketButton;

const StyledButton = styled(Link)`
  text-transform: uppercase;
  color: white;
  background-color: #444444;
  padding: 0 20px;
  height: 38px;
  border: none;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  display: flex;
  align-items: center;

  &:hover {
    background-color: ${PRIMARY};
    color: white;
  }
`;
