import React from "react";
import styled from "styled-components";
import "./DetailsTechnical.css";

const Technical = ({ part }) => {
  return (
    <>
      <StyledList>
        {part.technicaldetails.map((techinfo, k) => (
          <StyledElement key={k}>
            {!techinfo.value.includes("href=") ? (
              <>
                <div>{techinfo.name}</div>
                <div style={{ textAlign: "right" }}>{techinfo.value}</div>
              </>
            ) : (
              <>
                <div>{techinfo.name}</div>
                <div
                  dangerouslySetInnerHTML={{ __html: techinfo.value }}
                  style={{ textAlign: "right" }}
                />
              </>
            )}
          </StyledElement>
        ))}
        <StyledElement>
          <div>Weigth [KG]:</div>
          <div>{part.weight}</div>
        </StyledElement>
      </StyledList>
    </>
  );
};

export default Technical;

const StyledList = styled.ul`
  margin-top: 20px;
  list-style-type: none;
  padding: 0;
`;

const StyledElement = styled.li`
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
`;
