import React, { useState } from "react";
import styled from "styled-components";

const SummaryDropdown = ({ children, text }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div>
      <Dropdown onClick={() => setIsActive((prev) => !prev)}>
        <div>{text}</div>
        <div>
          {isActive ? (
            <i className="fas fa-angle-up" aria-hidden="true"></i>
          ) : (
            <i className="fas fa-angle-down" aria-hidden="true"></i>
          )}
        </div>
      </Dropdown>
      {isActive ? children : null}
    </div>
  );
};

export default SummaryDropdown;

const Dropdown = styled.div`
  display: flex;
  padding: 6px 10px;
  background: #fafafa;
  border: 1px solid #ddd;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 12px;
  color: #666666;
  cursor: pointer;
`;
