import React from "react";
import styled from "styled-components";
import { PRIMARY } from "../../../vars/vars";

const OutOfStock = () => (
  <>
    <Header>
      <i
        className="fa fa-times"
        aria-hidden="true"
        style={{ color: PRIMARY }}
      ></i>
      <StyledStock>Out of stock</StyledStock>
    </Header>
  </>
);

export default OutOfStock;

const Header = styled.div`
  display: flex;
  font-size: 20px;
  align-items: center;
`;

const StyledStock = styled.span`
  color: ${PRIMARY};
  margin-left: 5px;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
`;
