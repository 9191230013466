import React from "react";
import styled from "styled-components";
import { PRIMARY } from "../../../vars/vars";

const FormError = ({ text }) => <StyledError>{text}</StyledError>;

export default FormError;

const StyledError = styled.div`
  color: ${PRIMARY};
  margin-top: 5px;
  font-size: 12px;
  font-family: "Open Sans", sans-serif;
`;
