export const getErrorText = (error) =>
  error.response ? error.response.data.message : error.message;

export const createPayPalScript = (token, currency, callback) => {
  const script = document.createElement("script");
  script.src = `https://www.paypal.com/sdk/js?currency=${currency}&disable-funding=card&client-id=${token}`;

  document.body.appendChild(script);
  script.onload = () => {
    if (callback) callback();
  };
};
