import React, { useState } from "react";
import { Image, Wrapper } from "../DetailsPhoto/DetailsPhoto.module.scss";
import { LightBox } from "../../molecules/LightBox/LightBox";

const DetailsActivePhoto = ({ name, activePhoto, photos, isPhoto }) => {
  const [toggler, setToggler] = useState(false);
  return (
    <div
      className={Wrapper}
      style={{ position: "relative", cursor: "pointer" }}
    >
      <img
        onClick={() => setToggler(!toggler)}
        alt={name}
        src={(activePhoto && activePhoto.url) || activePhoto}
        className={Image}
      />
      {isPhoto && <LightBox images={photos} toggler={toggler} />}
    </div>
  );
};

export default DetailsActivePhoto;
