import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { PRIMARY } from "../../../vars/vars";

const FitmentNote = ({ text }) => {
  const [showFullInfo, setShowFullInfo] = useState(false);
  const fitmentNote = useRef(null);

  useEffect(() => {
    if (fitmentNote.current.scrollHeight <= 30) {
      setShowFullInfo(true);
    }
  }, []);

  return (
    <StyledFitmentNote>
      <Header>Fitment Note:</Header>
      <Text ref={fitmentNote} showAll={showFullInfo}>
        {text}
      </Text>
      {!showFullInfo && (
        <More onClick={() => setShowFullInfo(true)}>Read more...</More>
      )}
    </StyledFitmentNote>
  );
};

export default FitmentNote;

const StyledFitmentNote = styled.div`
  font-family: "Open Sans", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: #666666;
  padding: 10px;
  text-align: center;
`;

const Text = styled.div`
  max-height: ${(props) => (props.showAll ? "auto" : "30px")};
  overflow: hidden;
  line-height: 14px;
  margin: 5px 0;
`;

const More = styled.div`
  color: ${PRIMARY};
  font-weight: bold;

  &:hover {
    cursor: pointer;
  }
`;

const Header = styled.div`
  color: ${PRIMARY};
  font-weight: bold;
`;
