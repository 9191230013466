import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { PRIMARY } from "../../../vars/vars";

const DetailsComponents = ({ components, base }) => (
  <div>
    <h3>Contents of Kit {base} (Pedders Part Numbers)</h3>
    <ul>
      {components.map((item) => (
        <li key={item.id}>
          <StyledLink to={"/details/" + item.urlcomponent}>
            {item.name} x {item.quantity} - {item.description}
          </StyledLink>
        </li>
      ))}
    </ul>
  </div>
);

export default DetailsComponents;

const StyledLink = styled(Link)`
  &:hover {
    color: ${PRIMARY};
  }
`;
