import React from "react";
import styled from "styled-components";

const calculateDaysToDeliver = (date, delayDays = 0) => {
  const currentDate = new Date();
  const deliveryDate = new Date(date);

  const differenceInMilliseconds = deliveryDate - currentDate;
  const daysToDeliver = Math.ceil(
    differenceInMilliseconds / (24 * 60 * 60 * 1000)
  );

  const availableInDays = daysToDeliver;
  const availableInDaysWithDelay = daysToDeliver + delayDays;

  return `Available within ${availableInDays}-${availableInDaysWithDelay} days`;
};

const InTransit = ({ date, overThreeWeeks }) => (
  <>
    <Header>
      <i
        className="fa fa-check-square"
        aria-hidden="true"
        style={{ color: "#50be19" }}
      ></i>
      <StyledStock>In transit </StyledStock>
    </Header>

    <StyledAvl>
      {`${overThreeWeeks}`
        ? calculateDaysToDeliver(date, 7)
        : "Available within 2-3 weeks"}
    </StyledAvl>
  </>
);

export default InTransit;

const Header = styled.div`
  display: flex;
  font-size: 22px;
  gap: 5px;
  align-items: center;
`;

const StyledStock = styled.div`
  color: #50be19;
  font-weight: 700;
  font-family: "Open Sans", sans-serif;
`;

const StyledAvl = styled.div`
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: rgb(102, 102, 102);
`;
