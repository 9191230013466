import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { PRIMARY } from "../../../vars/vars";

const GoUp = () => {
  const [visible, setVisible] = useState(false);

  const scroll = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const setVisibility = () => {
      const value = window.scrollY > 500;
      setVisible(value);
    };

    window.addEventListener("scroll", setVisibility);
    return () => window.removeEventListener("scroll", setVisibility);
  }, []);

  return (
    <StyledGoUp onClick={scroll} visible={visible}>
      <i className="fa fa-arrow-up fa-lg" aria-hidden="true"></i>
    </StyledGoUp>
  );
};

export default GoUp;

const StyledGoUp = styled.div`
  z-index: 1000;
  position: fixed;
  bottom: 30px;
  right: 30px;
  padding: 6px 8px;
  background-color: ${PRIMARY};
  border-radius: 50%;
  color: white;
  cursor: pointer;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: opacity 0.2s;
`;
