export const countries = [
  { name: "Austria", iso: "AT" },
  { name: "Belgium", iso: "BE" },
  { name: "Bulgaria", iso: "BG" },
  { name: "Croatia", iso: "HR" },
  { name: "Cyprus", iso: "CY" },
  { name: "Czech Republic", iso: "CZ" },
  { name: "Denmark", iso: "DK" },
  { name: "Estonia", iso: "EE" },
  { name: "Finland", iso: "FI" },
  { name: "France", iso: "FR" },
  { name: "Germany", iso: "DE" },
  { name: "Greece", iso: "GR" },
  { name: "Hungary", iso: "HU" },
  { name: "Iceland", iso: "IS" },
  { name: "Ireland", iso: "IE" },
  { name: "Italy", iso: "IT" },
  { name: "Kazakhstan", iso: "KZ" },
  { name: "Latvia", iso: "LV" },
  { name: "Lithuania", iso: "LT" },
  { name: "Luxembourg", iso: "LU" },
  { name: "Malta", iso: "MT" },
  { name: "Netherlands", iso: "NL" },
  { name: "Norway", iso: "NO" },
  { name: "Poland", iso: "PL" },
  { name: "Portugal", iso: "PT" },
  { name: "Portugal - Azores", iso: "A2" },
  { name: "Portugal - Madeira", iso: "M3" },
  { name: "Romania", iso: "RO" },
  { name: "Slovakia", iso: "SK" },
  { name: "Slovenia", iso: "SI" },
  { name: "Spain", iso: "ES" },
  { name: "Spain - Canary Islands", iso: "IC" },
  { name: "Sweden", iso: "SE" },
  { name: "Switzerland", iso: "CH" },
];
