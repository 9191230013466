export const initialValues = {
  firstName: "",
  lastName: "",
  company: "",
  email: "",
  phone: "",
  billingAddress1: "",
  billingAddress2: "",
  billingAddressCity: "",
  billingAddressZip: "",
  billingAddressCountry: "",
  deliveryAddress1: "",
  deliveryAddress2: "",
  deliveryAddressCity: "",
  deliveryAddressZip: "",
  deliveryAddressCountry: "",
  country: "",
  vat: "",
  message: "",
  type: "",
  customer: "",
  www: "",
  specialisation: "",
  specialisationOther: "",
  area: "",
  willStock: "",
  privacyDealer: false,
  isDeliveryDifferent: false,
};
