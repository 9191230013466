import React from "react";
import "./MobileNavLink.scss";
import styled from "styled-components";
import { Link } from "react-router-dom";

const clickHandler = (display, active, handler) => {
  if (display === active) {
    handler("");
  } else {
    handler(display);
  }
};

const MobileNavLink = ({ to, display, children, active, handler }) => {
  return !!children ? (
    <Wrapper>
      <Item onClick={() => clickHandler(display, active, handler)}>
        <div className={"MobileNavLink"}>{display}</div>
        {active === display ? (
          <i clasclassNames="fa fa-caret-up" aria-hidden="true"></i>
        ) : (
          <i className="fa fa-caret-down" aria-hidden="true"></i>
        )}
      </Item>
      {active === display ? <Child>{children}</Child> : null}
    </Wrapper>
  ) : (
    <Wrapper>
      <Item>
        <Link className={"MobileNavLink"} to={to}>
          {display}
        </Link>
      </Item>
    </Wrapper>
  );
};

export default MobileNavLink;

const Child = styled.div`
  padding: 0 15px;
`;

const Item = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 10px 8px 0;
`;

const Wrapper = styled.div`
  border-bottom: 1px solid #ddd;
  display: flex;
  flex-direction: column;
`;
