import React from "react";
import styled from "styled-components";

const DesktopDropdownMenu = ({ children }) => (
  <StyledDesktopDropdownMenu className={"my-dropdown"}>
    {children}
  </StyledDesktopDropdownMenu>
);

export default DesktopDropdownMenu;

const StyledDesktopDropdownMenu = styled.div`
  z-index: 10;
  position: absolute;
  width: 100%;
  top: 25px;
  background-color: white;
  display: none;
  padding: 40px 20px;
  border: 1px solid #ccc;

  &:hover {
    display: block;
  }
`;
