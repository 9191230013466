import { useEffect, useCallback } from "react";
import { useFormikContext } from "formik";
import useBasketActions from "../../../../hooks/useBasketActions";
import debounce from "lodash/debounce";

const ZipCodeHandler = () => {
  const { values } = useFormikContext();
  const { updateFieldHandler } = useBasketActions();

  const updateZipDebounced = useCallback(
    debounce(async (zip) => {
      await updateFieldHandler("shipzip", zip);
    }, 1000),
    []
  );

  useEffect(() => {
    if (values.zip) {
      updateZipDebounced(values.zip);
    }
  }, [values.zip]);

  return null;
};

export default ZipCodeHandler;
