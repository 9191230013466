import React from "react";
import styled from "styled-components";
import SummaryHeader from "../../atoms/SummaryHeader/SummaryHeader";
import CheckoutDropdown from "../../molecules/CheckoutDropdown/CheckoutDropdown";
import CheckoutLine from "../../molecules/CheckoutLine/CheckoutLine";
import BasketAdditionalData from "../../molecules/BasketAdditionalData/BasketAdditionalData";
import { useAppContext } from "../../../context/AppContext";

const OrderSummary = ({ header, full }) => {
  const { basketCount, basket } = useAppContext();

  return (
    <div>
      <StyledComponent>
        <SummaryHeader text={header} />
        {full ? (
          <BasketAdditionalData />
        ) : (
          <>
            <CheckoutDropdown quantity={basketCount}>
              {basket &&
                basket.transactionlines
                  .filter((item) => item.kitcomponent !== true)
                  .map((item) => (
                    <CheckoutLine
                      key={item._id}
                      item={item}
                      currency={basket.currency.symbol}
                    />
                  ))}
            </CheckoutDropdown>
            <BasketAdditionalData />
          </>
        )}
      </StyledComponent>
    </div>
  );
};

export default OrderSummary;

const StyledComponent = styled.section`
  max-width: 600px;
  padding: 10px;
  background-color: #fafafa;
`;
