import React from "react";
import { Spinner } from "react-bootstrap";
import styled from "styled-components";

const StyledLoader = styled(Spinner)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
`;

const Loader = ({ show }) =>
  show && (
    <StyledLoader>
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
    </StyledLoader>
  );

export default Loader;
