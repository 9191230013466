import React from "react";
import styled from "styled-components";
import DetailsRelatedItem from "../DetailsRelatedItem/DetailsRelatedItem";

const DetailsRelated = ({ relatedItems }) => (
  <RelatedItemList>
    {relatedItems.map((item) => (
      <DetailsRelatedItem {...item} />
    ))}
  </RelatedItemList>
);

export default DetailsRelated;

const RelatedItemList = styled.section`
  display: grid;
  grid-template-columns: repeat(1, 1fr);

  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;
