import React, { useState } from "react";
import styled from "styled-components";

const CheckoutDropdown = ({ children, quantity }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <Wrapper>
      <Dropdown onClick={() => setIsActive((prev) => !prev)}>
        <div>
          {quantity || 0} item{quantity > 1 && "s"} in Cart
        </div>
        <div>
          {isActive ? (
            <i className="fas fa-angle-up" aria-hidden="true"></i>
          ) : (
            <i className="fas fa-angle-down" aria-hidden="true"></i>
          )}
        </div>
      </Dropdown>
      {isActive ? children : null}
    </Wrapper>
  );
};

export default CheckoutDropdown;

const Wrapper = styled.div`
  margin-bottom: 10px;
`;

const Dropdown = styled.div`
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
  display: flex;
  background: #fafafa;
  justify-content: space-between;
  align-items: center;
  color: #666666;
  cursor: pointer;
  border-bottom: 1px solid #d1d1d1;
  padding: 10px 10px 10px 0;
`;
