import React, { useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router";

const SummaryHeader = ({ text, cog, active }) => {
  const [isHovered, setIsHovered] = useState(false);
  const history = useHistory();

  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const iconStyle = {
    color: isHovered ? "black" : "#8f8f8f",
    cursor: "pointer",
    fontSize: "1.25em",
    transition: "color 0.3s ease",
  };

  return (
    <StyledComponent>
      <div>{text}</div>
      {cog && active && (
        <i
          className="fa fa-cog fa-lg gtm_btn_checkout"
          aria-hidden="true"
          style={iconStyle}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={() => history.push(cog)}
        ></i>
      )}
    </StyledComponent>
  );
};

export default SummaryHeader;

const StyledComponent = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  font-family: "Open Sans", sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  color: #333333;
  padding-bottom: 10px;
  border-bottom: 1px solid #d1d1d1;
  align-items: center;
`;
