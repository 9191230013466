import React from "react";
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import SnackbarProvider from "react-simple-snackbar";
import MainLayout from "./components/layouts/MainLayout/MainLayout";
import LayoutContextProvider from "./context/LayoutContextProvider";
import Backdrop from "./components/organisms/Backdrop/Backdrop";
import Drawer from "./components/organisms/Drawer/Drawer";
import ScrollToTop from "./components/organisms/ScrollToTop/ScrollToTop";
import GoUp from "./components/organisms/GoUp/GoUp";
import { CookieContainer } from "./components/organisms/CookieContainer/CookieContainer";
import AppContextProvider from "./context/AppContext";
import ConsentContextProvider from "./context/ConsentContext";

function App() {
  return (
    <>
      <Router>
        <SnackbarProvider>
          <ScrollToTop />
          <GoUp />
          <AppContextProvider>
            <ConsentContextProvider>
              <LayoutContextProvider>
                <Drawer />
                <CookieContainer />
                <Backdrop />
                <MainLayout />
              </LayoutContextProvider>
            </ConsentContextProvider>
          </AppContextProvider>
        </SnackbarProvider>
      </Router>
    </>
  );
}

export default App;
