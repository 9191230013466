export const TAX_OBJ = {
  ZERO: "5e0dbaba9e33df43f0b3a480",
  EU: "5e0dbaba9e33df43f0b3a47f", // 23%
};
export const TAX_FLOAT_OBJ = {
  ZERO: 1,
  EU: 1.23,
};

export const PAYMENT_METHODS = {
  PAYPAL: "607468324e5b797a767fe87d",
  REVOLUT: "revolut",
};
export const PRIMARY = "#dc3332";
export const SECONDARY = "#666666";
export const BREAKPOINT = "576px";
export const SOURCE = "pedderseu";
export const CURRENCY = "eur";
export const CUSTOMER = "guest";
export const TAX = "5e0dbaba9e33df43f0b3a47f";
export const TAX_FLOAT = 1.23;
export const PAYPAL_BASE =
  "https://www.paypal.com/sdk/js?currency=EUR&disable-funding=card&client-id=";
export const PAYPAL_SANDBOX =
  "AUnK_LGFj8QlZOUcmvlzQkDrH_rM5q2RUnYJBAvTlrDgcCuu6S7blC236LfvYNIgRm11kUQslqMz3NBh";
export const PAYPAL_LIVE =
  "AXh4pCJMem_WIyl_zXJ7KpD-cnV25y9UsX0FKbDbonxLt02aoRTwIhJ0HwPk2S7lHRhdHIkqQ-VHwohr";

export const ACTION = {
  CREATE_LEAD: "addCustomer",
  GET_CARTS: "getCarts",
  ADD_TO_CART: "addToCart",
  UPDATE_CART: "updateCart",
  GET_PAYMENT_METHODS: "getPaymentMethods",
};

export const LOCATION = {
  EU: "5e0dbaba9e33df43f0b3a495",
};

export const deliveryTime = {
  IN_STOCK: "Delivery within 2-4 days",
  IN_TRANSIT: "Product in transit - delivery 2-3 weeks",
  IN_MANUFACTURER: "Delivery within 4 weeks",
};

export const WIDGET_TYPE = {
  REVOLUT: "Revolut",
  PAYPAL: "PayPal",
};

export const HOTJAR_EU = 5089030;

export const GTM_EU = "GTM-PPJZ58V";

export const COOKIESNAME = "pedders-cookie-consents";
