export const tabs = [
  {
    name: "Details",
  },
  {
    name: "Vehicle Fitments",
  },
  {
    name: "Technical Notes",
  },
  {
    name: "Related Items",
  },
];
