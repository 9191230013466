import React from "react";
import { Switcher } from "../DetailsPhoto/DetailsPhoto.module.scss";

const DetailsPhotoSwitch = ({ photos, name, clickHandler }) => {
  return (
    <div className={Switcher}>
      {photos &&
        photos.map((photo) => (
          <div key={photo.url}>
            <img
              alt={name}
              src={photo.url}
              name={name}
              key={photo.url}
              onClick={() => clickHandler(photo.url)}
            />
          </div>
        ))}
    </div>
  );
};

export default React.memo(
  DetailsPhotoSwitch,
  (prev, curr) => prev.photos === curr.photos
);
