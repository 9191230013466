import React, { useState } from "react";
import styled from "styled-components";
import { PRIMARY } from "../../../vars/vars";
import placeholderWebp from "../../../assets/placeholders/pedders_placeholder.webp";
import placeholder from "../../../assets/placeholders/pedders_placeholder.jpg";
import { useAppContext } from "../../../context/AppContext";

const MiniBasketItem = ({ item, currency, deleteHandler, changeHanlder }) => {
  const [quantity, setQuantity] = useState(item.quantity);
  const { basketId } = useAppContext();

  const onClickHandler = (e, id) => {
    const { value } = e.target;
    setQuantity(value);
    changeHanlder(value, id, basketId);
  };

  return (
    <StyledMiniBasketItem>
      <Photo>
        {item.item.photos.length ? (
          <img src={item.item.photos[0].url} alt={item.displayname} />
        ) : (
          <picture>
            <source srcSet={placeholderWebp} type="image/webp" />
            <img src={placeholder} alt="logo" />
          </picture>
        )}

        <Delete onClick={() => deleteHandler(item._id)}>
          <i className="fa fa-times-circle fa-lg" aria-hidden="true"></i>
        </Delete>
      </Photo>
      <Data>
        <Description>{item.item.description}</Description>
        <Controls>
          <div>
            <StyledSpan>Qty: </StyledSpan>
            <StyledInput
              disabled
              type={"number"}
              min={1}
              value={quantity}
              onChange={(e) => onClickHandler(e, item._id)}
            />
          </div>
          <Price>
            {currency} {item.grosamount}
          </Price>
        </Controls>
      </Data>
    </StyledMiniBasketItem>
  );
};

export default MiniBasketItem;

const StyledSpan = styled.span`
  display: inline-block;
  color: #666666;
  font-size: 12px;
  font-family: "Open Sans", sans-serif;
`;

const StyledInput = styled.input`
  width: 35px;
  margin-left: 10px;
  text-align: center;
  padding: 0px;
  background: white;

  &[type="number"] {
    -moz-appearance: textfield;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const Price = styled.div`
  font-family: "Open Sans", sans-serif;
  color: ${PRIMARY};
  font-weight: 700;
  font-size: 16px;
`;

const Description = styled.div`
  color: #444444;
  font-size: 13px;
  font-family: "Open Sans", sans-serif;
  text-align: left;
`;

const StyledMiniBasketItem = styled.div`
  display: grid;
  grid-template-columns: 4fr 6fr;
`;

const Photo = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  height: 100px;
  align-items: center;

  img {
    border: 1px solid #ddd;
    width: 100px;
    height: 80px;
    object-fit: contain;
    background-color: white;
  }
`;

const Delete = styled.div`
  position: absolute;
  top: 4px;
  right: 1px;

  &:hover {
    cursor: pointer;
    color: ${PRIMARY};
  }
`;

const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
`;

const Data = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 8px;
`;
