import React, { useContext, useState } from "react";
import styled from "styled-components";
import layoutContext from "../../../context/LayoutContext";
import styles from "./Drawer.module.scss";
import { links } from "./links";
import MobileNavLink from "../../atoms/MobileNavLink/MobileNavLink";
import { withRouter } from "react-router";

const Drawer = () => {
  const context = useContext(layoutContext);
  const [active, setActive] = useState("");

  return (
    <StyledDrawer
      className={context.showDrawer ? styles.active : styles.inactive}
    >
      {links.map(({ display, to, children }) => (
        <MobileNavLink
          key={display}
          display={display}
          to={to}
          active={active}
          handler={setActive}
        >
          {children}
        </MobileNavLink>
      ))}
    </StyledDrawer>
  );
};

export default withRouter(Drawer);

const StyledDrawer = styled.div`
  position: fixed;
  padding: 20px 10px;
  top: 0;
  left: 0;
  width: 250px;
  height: 100%;
  z-index: 1000;
  background-color: white;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
`;
