import axios from "axios";
import { CURRENCY, CUSTOMER, SOURCE } from "../vars/vars";

const axios3c = axios.create({
  baseURL: "https://3cerp.eu/api/external",
});

axios3c.interceptors.request.use(
  function (config) {
    if (config.data) {
      config.data.source = SOURCE;
      config.data.currency = CURRENCY;
      config.data.customer = CUSTOMER;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default axios3c;
