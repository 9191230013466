import React from "react";
import { Button, Modal } from "react-bootstrap";

const UniversalModal = ({ text, clickHandler }) => (
  <Modal show={true} backdrop="static" keyboard={false}>
    <Modal.Header>
      <Modal.Title>Info</Modal.Title>
    </Modal.Header>

    <Modal.Body>
      <p>{text}</p>
    </Modal.Body>

    <Modal.Footer>
      <Button onClick={clickHandler} variant="secondary">
        Close
      </Button>
    </Modal.Footer>
  </Modal>
);

export default UniversalModal;
