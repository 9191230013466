import React from "react";
import styled from "styled-components";
import { PRIMARY } from "../../../vars/vars";

const PlaceOrderButton = ({ placeOrder, loading }) => (
  <StyledButton
    onClick={placeOrder}
    disabled={loading}
    className="gtm_btn_placeorder"
  >
    {loading ? (
      <i className="fa fa-cog fa-spin fa-lg" aria-hidden="true"></i>
    ) : (
      "Place Order and Pay"
    )}
  </StyledButton>
);

export default PlaceOrderButton;

const StyledButton = styled.button`
  margin-top: 50px;
  color: white;
  width: 100%;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  outline: none;
  border: none;
  background-color: #444;
  height: 35px;
  text-transform: uppercase;
  font-weight: 400;
  padding: 0 20px;
  cursor: ${(props) => (props.disabled ? "auto" : "pointer")};

  &:hover {
    background-color: ${(props) => (props.disabled ? null : PRIMARY)};
  }
`;
