import { PRIMARY } from "../../../vars/vars";
import React from "react";

export const services = [
  {
    icon: (
      <i
        className="fa fa-check-circle fa-2x"
        aria-hidden="true"
        style={{ color: PRIMARY }}
      ></i>
    ),
    header: "EXPERT ADVICE",
    text: "Confirm the correct fitment, first time.",
  },
  {
    icon: (
      <i
        className="fa fa-cubes fa-2x"
        aria-hidden="true"
        style={{ color: PRIMARY }}
      ></i>
    ),
    header: "LIVE STOCK CHECK",
    text: "To ensure consistent availability.",
  },
  {
    icon: (
      <i
        className="fa fa-calendar fa-2x"
        aria-hidden="true"
        style={{ color: PRIMARY }}
      ></i>
    ),
    header: "Reliable & Fast delivery",
    text: "Check our shipping conditions here.",
    to: "/shipping",
  },
];
