import React from "react";
import styled from "styled-components";

const Sticky = ({ top, children }) => (
  <Component top={top}>{children}</Component>
);

export default Sticky;

const Component = styled.div`
  position: sticky;
  top: ${(props) => props.top || 20 + "px"};
  width: 100%;
`;
