import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { PRIMARY } from "../../../vars/vars";

const DetailsService = ({ icon, header, text, to }) => (
  <StyledDetailsService>
    {icon}
    <Header>{header}</Header>
    {to ? (
      <StyledLink to={to}>{text}</StyledLink>
    ) : (
      <StyledText>{text}</StyledText>
    )}
  </StyledDetailsService>
);

export default DetailsService;

const StyledDetailsService = styled.div`
  padding: 25px 0;

  &:not(:last-of-type) {
    border-bottom: 1px dashed #ebebeb;
  }
`;

const Header = styled.div`
  color: #444444;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 14px;
`;

const StyledText = styled.p`
  font-size: 12px;
  color: #666666;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
`;

const StyledLink = styled(Link)`
  font-size: 12px;
  color: #666666;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;

  &:hover {
    cursor: pointer;
    color: ${PRIMARY};
  }
`;
