import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";

const MobileNavMenu = ({ children }) => (
  <StyledMobileNavMenu>{children}</StyledMobileNavMenu>
);

export default MobileNavMenu;

const StyledMobileNavMenu = styled(Container)`
  padding: 10px;
  height: 70px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (min-width: 992px) {
    display: none;
  }
`;
