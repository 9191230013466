import React from "react";
import styled from "styled-components";
import { Container } from "react-bootstrap";
import Logo from "../../atoms/Logo/Logo";
import DesktopNavMenu from "../../molecules/DesktopNavMenu/DesktopNavMenu";
import SearchArea from "../../molecules/SearchArea/SearchArea";
import SmallShoppingCart from "../../molecules/SmallShoppingCart/SmallShoppingCart";
import MobileNavMenu from "../../molecules/MobileNavMenu/MobileNavMenu";
import Hamburger from "../../atoms/Hamburger/Hamburger";

const Navbar = () => (
  <StyledNav>
    <Container>
      <NavContent>
        <LogoContainer>
          <Logo />
        </LogoContainer>
        <Content>
          <DesktopNavMenu />
          <SearchArea />
          <SmallShoppingCart />
        </Content>
        <MobileNavMenu>
          <MobileContent>
            <Hamburger />
            <Logo />
            <SmallShoppingCart />
          </MobileContent>
        </MobileNavMenu>
      </NavContent>
    </Container>
  </StyledNav>
);

export default Navbar;

const StyledNav = styled.nav`
  padding-top: 30px;
`;

const MobileContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const NavContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  @media screen and (min-width: 1200px) {
    flex-direction: row;
  }
`;

const Content = styled.div`
  display: none;

  @media screen and (min-width: 992px) {
    display: flex;
    align-items: center;
  }
`;

const LogoContainer = styled.div`
  display: none;

  @media screen and (min-width: 992px) {
    display: block;
  }
`;
