import React from "react";
import styles from "./NowShoppingBy.module.scss";
import styled from "styled-components";

const NowShoppingBy = ({ category, resetHandler }) => (
  <div className={styles.NowShoppingBy}>
    <div className={styles.Header}>Now Shopping By</div>
    <div>
      <span className={styles.Subheader}>Category:</span> {category}
    </div>
    <Actions>
      <button className={styles.Button} onClick={resetHandler}>
        Clear all
      </button>
    </Actions>
  </div>
);

export default NowShoppingBy;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
`;
