import React from "react";
import styled from "styled-components";
import DetailsService from "../../molecules/DetailsService/DetailsService";
import { services } from "./services";

const DetailsServices = () => (
  <div>
    <StyledDetailsServices>
      {services.map((service) => (
        <DetailsService {...service} key={service.header} />
      ))}
    </StyledDetailsServices>
  </div>
);

export default DetailsServices;

const StyledDetailsServices = styled.div`
  border: 1px solid #ebebeb;
  border-radius: 3px;
  padding: 10px 20px;
  margin-bottom: 30px;
`;
