import React from "react";
import styled from "styled-components";
import { Container } from "react-bootstrap";
import FooterGroup from "../../molecules/FooterGroup/FooterGroup";
import links from "../../../vars/footerLinks";

const Footer = () => (
  <StyledFooter>
    <Container>
      <Grid>
        {links.map((props) => (
          <FooterGroup key={props.header} {...props} />
        ))}
      </Grid>
    </Container>
  </StyledFooter>
);

export default Footer;

const Grid = styled.div`
  display: grid;
  
  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (min-width: 992px) {
    grid-template-columns: 1.5fr 1fr 1fr 1fr;
  }
`;

const StyledFooter = styled.footer`
  background-color: #f5f5f5;
`;
