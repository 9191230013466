import React from "react";
import styled from "styled-components";
import { useAppContext } from "../../../context/AppContext";
import SummaryDropdown from "../../molecules/SummaryDropdown/SummaryDropdown";
import DiscountCode from "../../molecules/DiscountCode/DiscountCode";

const BasketAdditionalData = () => {
  const { basket } = useAppContext();

  return basket ? (
    <div>
      <AdditionalData>
        <div>Cart subtotal</div>
        <div>
          {basket.currency.symbol} {basket.grossitemsamount.toFixed(2)}
        </div>
      </AdditionalData>
      <AdditionalData>
        <div>Shipping</div>
        <div>
          {basket.currency.symbol} {basket.grossshippingcost.toFixed(2)}
        </div>
      </AdditionalData>{" "}
      <AdditionalData>
        <div>Tax</div>
        <div>
          {basket.currency.symbol} {basket.taxamount.toFixed(2)}
        </div>
      </AdditionalData>
      <AdditionalData>
        <div style={{ fontWeight: "700" }}>Order Total</div>
        <Summary>
          {basket.currency.symbol} {basket.grossamount.toFixed(2)}
        </Summary>
        {basket.transactionlines[0].discountrate ? (
          <DiscountInfo>
            <p>
              Incl.{" "}
              {Math.abs(Number(basket.transactionlines[0].discountrate)) * 100}%
              discount{" "}
            </p>
          </DiscountInfo>
        ) : null}
      </AdditionalData>
      {!!basket.backorderquantity && (
        <AdditionalData>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: "10px",
              paddingTop: "10px",
              borderTop: "1px solid #ccc",
            }}
          >
            <div style={{ fontSize: "11px" }}>
              *As at least one of products has prolonged delivery time, the
              whole order will be delivered when completed
            </div>
          </div>
        </AdditionalData>
      )}
      <SummaryDropdown text={"Add Promo Code"}>
        <DiscountCode />
      </SummaryDropdown>
    </div>
  ) : null;
};

export default BasketAdditionalData;

const AdditionalData = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-between;
  font-family: "Open Sans", sans-serif;
  color: #666666;
  padding: 8px 0;
  font-size: 12px;
`;

const Summary = styled.div`
  font-weight: bold;
  margin-top: 5px;
`;

const DiscountInfo = styled.div`
  width: 100%;
  color: red;
  text-align: right;
  font-weight: 700;
`;
