import subaruForresterWebp from "./../../../assets/popular-vehicles/subaru-forrester.webp";
import subaruForrester from "./../../../assets/popular-vehicles/subaru-forrester.jpg";
import nissanNavara from "./../../../assets/popular-vehicles/nissan-navara.jpg";
import nissanNavaraWebp from "./../../../assets/popular-vehicles/nissan-navara.webp";
import mitsubishiPajero from "./../../../assets/popular-vehicles/mitsubishi-pajero.jpg";
import mitsubishiPajeroWebp from "./../../../assets/popular-vehicles/mitsubishi-pajero.webp";
import toyotaLand from "./../../../assets/popular-vehicles/toyota-land.jpg";
import toyotaLandWebp from "./../../../assets/popular-vehicles/toyota-land.webp";
import nissanX from "./../../../assets/popular-vehicles/nissan-x.jpg";
import nissanXWebp from "./../../../assets/popular-vehicles/nissan-x.webp";

export const vehicles = [
  {
    name: "Subaru Forester",
    image: subaruForrester,
    imageWebp: subaruForresterWebp,
    to: "/info-subaru-forester",
  },
  {
    name: "Nissan Navara",
    image: nissanNavara,
    imageWebp: nissanNavaraWebp,
    to: "/info-nissan-navara",
  },
  {
    name: "Mitsubishi Pajero",
    image: mitsubishiPajero,
    imageWebp: mitsubishiPajeroWebp,
    to: "/info-mitsubishi-pajero",
  },
  {
    name: "Toyota Landcruiser",
    image: toyotaLand,
    imageWebp: toyotaLandWebp,
    to: "/info-toyota-landcruiser",
  },
  {
    name: "Nissan X-Trail",
    image: nissanX,
    imageWebp: nissanXWebp,
    to: "/info-nissan-x-trail",
  },
];
