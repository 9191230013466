import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

const Applications = ({ applications }) => {
  const history = useHistory();
  const handleRowClick = (item) => {
    const url = new URL("parts", window.location);
    url.searchParams.append("make", item.make);
    url.searchParams.append("year", item.year);
    url.searchParams.append("model", item.model);

    history.push(`/parts${url.search}`);
  };

  return (
    <>
      <StyledTable>
        <thead>
          <tr>
            <TableHeader>Make</TableHeader>
            <TableHeader>Model</TableHeader>
            <TableHeader>Year</TableHeader>
            <TableHeader>Group</TableHeader>
            <TableHeader>Parameter</TableHeader>
          </tr>
        </thead>
        <tbody>
          {applications.map((item, k) => (
            <TableRow key={k} onClick={() => handleRowClick(item)}>
              <TableBody>{item.make}</TableBody>
              <TableBody>{item.model}</TableBody>
              <TableBody>{item.year}</TableBody>
              <TableBody>{item.groupdescription}</TableBody>
              <TableBody>{item.parameter}</TableBody>
            </TableRow>
          ))}
        </tbody>
      </StyledTable>
    </>
  );
};

export default Applications;

const StyledTable = styled.table`
  width: 100%;
  margin-botton: 16px;
`;

const TableRow = styled.tr`
  cursor: pointer;
  tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.08);
  }
`;

const TableHeader = styled.th`
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
  border-top: 1px solid #dee2e6;
  padding: 12px 8px;
`;

const TableBody = styled.td`
  border-top: 1px solid #dee2e6;
  font-size: 13px;
  padding: 12px 8px;
`;
