import React from "react";
import { Route, Switch } from "react-router-dom";

const RouterView = ({ routes }) => (
  <Switch>
    {routes.map(({ path, component, exact }) => (
      <Route key={path} exact={exact} path={path} component={component} />
    ))}
  </Switch>
);

export default RouterView;
