export const linksLeft = [
    {
      make: "Chrysler",
      models: [{ to: "/info-chrysler-300c", display: "Chrysler 300C" }],
    },
  {
    make: "Dacia",
    models: [{ to: "/info-dacia-duster", display: "Duster" }],
  },
  {
    make: "Ford",
    models: [
        { to: "/info-ford-ranger", display: "Ranger" },
      { to: "/info-ford-mustang", display: "Mustang" },
    ],
  },
  {
    make: "Hyundai",
    models: [{ to: "/info-hyundai-santa-fe", display: "Santa Fe" }],
  },
  {
    make: "Isuzu",
    models: [{ to: "/info-isuzu-d-max", display: "D-Max" }],
  },
  {
    make: "Jeep",
    models: [{ to: "/info-jeep-wrangler", display: "Wrangler" }],
  },
  {
    make: "Kia",
    models: [{ to: "/info-kia-sorento", display: "Sorento" }],
  },
  {
    make: "Mercedes",
    models: [{ to: "/info-mercedes-x-class", display: "X-Class" }],
  },
  {
    make: "Mazda",
    models: [{ to: "/info-mazda-mx5", display: "MX5" }],
  },
  {
    make: "Mitsubishi",
    models: [
      { to: "/info-mitsubishi-l200", display: "L200" },
      { to: "/info-mitsubishi-pajero", display: "Pajero & Shogun" },
      { to: "/info-mitsubishi-shogun", display: "Shogun Sport" },
    ],
  },
];

export const linksRight = [
  {
    make: "Nissan",
    models: [
      { to: "/info-nissan-navara", display: "Navara" },
      { to: "/info-nissan-pathfinder", display: "Pathfinder" },
      { to: "/info-nissan-patrol", display: "Patrol" },
      { to: "/info-nissan-x-trail", display: "X-Trail" },
    ],
  },
  {
    make: "Subaru",
    models: [
      { to: "/info-subaru-forester", display: "Forester" },
      { to: "/info-subaru-impreza", display: "Impreza" },
      { to: "/info-subaru-outback", display: "Outback" },
      { to: "/info-subaru-brz", display: "BRZ" },
    ],
  },
  {
    make: "Toyota",
    models: [
      { to: "/info-toyota-hilux", display: "Hilux 4WD" },
      { to: "/info-toyota-landcruiser", display: "Landcruiser" },
      { to: "/info-toyota-gt86", display: "GT86" },
    ],
  },
  {
    make: "Volkswagen",
    models: [
        { to: "/info-volkswagen-amarok", display: "Amarok" },
      { to: "/info-volkswagen-transporter", display: "Transporter" },
      { to: "/info-volkswagen-golf", display: "Golf" },
    ],
  },
];
