import React from "react";
import styled from "styled-components";
import { PRIMARY } from "../../../vars/vars";

const PartFinderHeader = ({ text }) => <StyledHeader>{text}</StyledHeader>;

export default PartFinderHeader;

const StyledHeader = styled.h2`
  color: ${PRIMARY};
  font-size: 30px;
  padding-top: 30px;
  margin-bottom: 20px;
  font-weight: bold;
  margin-left: 10px;
  @media (max-width: 420px) {
    font-size: 25px;
  }
`;
