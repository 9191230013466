import React from "react";
import styled from "styled-components";
import { countries } from "../../../vars/countries";
import { useAppContext } from "../../../context/AppContext";

const EstimateDelivery = ({ value, changeHandler, radioChangeHandler }) => {
  const { basket, basketOptions } = useAppContext();

  return (
    <StyledEstimateDelivery>
      <Header>Enter your destination to get a shipping estimate.</Header>
      <StyledLabel htmlFor={"estimate"}>Country</StyledLabel>
      <StyledSelect id={"estimate"} value={value} onChange={changeHandler}>
        <option disabled value={""}>
          Country
        </option>
        {countries
          .filter((country) => !["GB"].includes(country.iso))
          .map(({ iso, name }) => (
            <option key={iso} value={iso}>
              {name}
            </option>
          ))}
      </StyledSelect>
      {basketOptions.shippingmethod && basketOptions.shippingmethod.length ? (
        <>
          <DeliveryMethod>Delivery Method</DeliveryMethod>
          <Radios>
            {basketOptions.shippingmethod.map((method) => (
              <div key={method._id}>
                <input
                  type="radio"
                  id={method._id}
                  name={method.displayname || method.name}
                  value={method._id}
                  onChange={radioChangeHandler}
                  checked={basket.shippingmethod === method._id}
                />
                <label htmlFor={method._id}>
                  {method.displayname || method.name}
                </label>
              </div>
            ))}
          </Radios>
        </>
      ) : null}
    </StyledEstimateDelivery>
  );
};

export default EstimateDelivery;

const Header = styled.div`
  color: #666666;
  font-size: 12px;
  font-weight: 500;
  margin-top: 10px;
`;

const StyledEstimateDelivery = styled.div`
  display: flex;
  flex-direction: column;
  font-family: "Open Sans", sans-serif;
`;

const StyledLabel = styled.label`
  color: #666666;
  font-size: 12px;
  font-weight: 500;
  margin-top: 10px;
`;

const StyledSelect = styled.select`
  height: 35px;
  border: 1px solid #adadad;
  background-color: #fff;
  border-radius: 1px;
  font-size: 12px;
  padding: 0 10px;

  &::placeholder {
    color: #c2c2c2;
    font-size: 12px;
  }
`;

const DeliveryMethod = styled.div`
  font-size: 12px;
  color: #666666;
  font-weight: 500;
  margin: 10px 0;
`;

const Radios = styled.div`
  font-size: 12px;
  color: #666666;
  font-family: "Open Sans", sans-serif;

  label {
    margin: 0 20px 0 5px;
  }
`;
