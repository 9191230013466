import React from "react";
import styled from "styled-components";
import OrderLine from "../../molecules/OrderLine/OrderLine";
import OrderKit from "../../molecules/OrderKit/OrderKit";

const OrderCart = ({ lines, currency }) => (
  <Component>
    <StyledTable>
      <TableHeader>Item</TableHeader>
      <TableHeader>Price</TableHeader>
      <TableHeader>Qty</TableHeader>
      <TableHeader>Subtotal</TableHeader>
      {lines
        .filter((item) => item.price !== 0)
        .map((item) =>
          item.item.recordtype === "kititem" ? (
            <OrderKit key={item._id} item={item} currency={currency} />
          ) : (
            <OrderLine key={item._id} item={item} currency={currency} />
          )
        )}
    </StyledTable>
  </Component>
);

export default OrderCart;

const Component = styled.section`
  grid-area: basket;
`;

const StyledTable = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr 1fr 1fr;
`;

const TableHeader = styled.div`
  color: #666666;
  font-size: 12px;
  border-bottom: 2px solid #dee2e6;
  border-top: 1px solid #dee2e6;
  text-transform: uppercase;
  padding-top: 20px;
  padding-bottom: 10px;
  font-weight: 700;
  &:not(:first-of-type) {
    text-align: right;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;
