import React from "react";
import { Alert } from "react-bootstrap";
import styled from "styled-components";

const DetailsAlert = ({ part, paid, other }) => (
  <Alert variant={"success"}>
    <i
      className="fa fa-check"
      aria-hidden="true"
      style={{ color: "#006400" }}
    ></i>
    {paid ? (
      <StyledSpan>Your order has beed paid.</StyledSpan>
    ) : part ? (
      <StyledSpan>
        You added {part.description} {part.name} to your shopping cart.
      </StyledSpan>
    ) : (
      <StyledSpan>{other}</StyledSpan>
    )}
  </Alert>
);

export default DetailsAlert;

const StyledSpan = styled.span`
  display: inline-block;
  margin-left: 10px;
`;
