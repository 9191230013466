import React from "react";
import DesktopNavLink from "../../atoms/DesktopNavLink/DesktopNavLink";
import styled from "styled-components";
import { links } from "./links";

const DesktopNavMenu = () => (
  <StyledDesktopNavMenu>
    {links.map(({ display, to, children }) => (
      <DesktopNavLink
        key={display}
        display={display}
        to={to}
        children={children}
      />
    ))}
  </StyledDesktopNavMenu>
);

export default DesktopNavMenu;

const StyledDesktopNavMenu = styled.div`
  position: relative;
`;
