import airbags from "../../../assets/ribbon/Airbag_black.webp";
import brakes from "../../../assets/ribbon/Brakes_Black.webp";
import bushes from "../../../assets/ribbon/Bushes_Black.webp";
import driveline from "../../../assets/ribbon/Driveline_CV_Shaft_Black.webp";
import springs from "../../../assets/ribbon/Springs_and_Shocks_Black.webp";
import steering from "../../../assets/ribbon/Steering_Black.webp";
import kits from "../../../assets/ribbon/Suspension_kits_Black.webp";
import parts from "../../../assets/ribbon/Suspension_parts_Black.webp";
import other from "../../../assets/ribbon/Others_Black.webp";

import airbagsAlt from "../../../assets/ribbon/Airbag_white.webp";
import brakesAlt from "../../../assets/ribbon/Brakes_White.webp";
import bushesAlt from "../../../assets/ribbon/Bushes_White.webp";
import drivelineAlt from "../../../assets/ribbon/Driveline_CV_Shaft_White.webp";
import springsAlt from "../../../assets/ribbon/Springs_and_Shocks_White.webp";
import steeringAlt from "../../../assets/ribbon/Steering_White.webp";
import kitsAlt from "../../../assets/ribbon/Suspension_kits_White.webp";
import partsAlt from "../../../assets/ribbon/Suspension_parts_white.webp";
import otherAlt from "../../../assets/ribbon/Others_White.webp";

export const images = {
  AIRBAGS: airbags,
  BRAKES: brakes,
  BUSHES: bushes,
  DRIVELINE: driveline,
  "SPRINGS AND SHOCKS": springs,
  STEERING: steering,
  "SUSPENSION KITS": kits,
  "SUSPENSION PARTS": parts,
  OTHER: other,
};

export const altImages = {
  AIRBAGS: airbagsAlt,
  BRAKES: brakesAlt,
  BUSHES: bushesAlt,
  DRIVELINE: drivelineAlt,
  "SPRINGS AND SHOCKS": springsAlt,
  STEERING: steeringAlt,
  "SUSPENSION KITS": kitsAlt,
  "SUSPENSION PARTS": partsAlt,
  OTHER: otherAlt,
};
