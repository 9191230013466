import React from "react";
import styled from "styled-components";

const Header = ({ text, bolder }) => <Text bolder={bolder}>{text}</Text>;

export default Header;

const Text = styled.h3`
  font-weight: 700;
  font-size: ${(props) => (props.bolder ? "20px" : "1em")};
  text-transform: uppercase;
  margin-bottom: 0;
  color: #444444;
`;
