import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { PRIMARY } from "../../../vars/vars";
import placeholderWebp from "../../../assets/placeholders/pedders_placeholder.webp";
import placeholder from "../../../assets/placeholders/pedders_placeholder.jpg";

const OrderKitComponent = ({ item }) => {
  return (
    <>
      <KitComponentWrapper>
        <PhotoContainer to={`/details/${item.urlcomponent}`} target="_blank">
          {item.photos.length ? (
            <img
              style={{
                width: "100%",
                objectFit: "contain",
                height: "80px",
              }}
              src={item.photos[0].url}
              alt={item.displayname}
            />
          ) : (
            <picture>
              <source srcSet={placeholderWebp} type="image/webp" />
              <img src={placeholder} alt={item.displayname} />
            </picture>
          )}
        </PhotoContainer>
        <div>
          <Description to={`/details/${item.urlcomponent}`} target="_blank">
            {item.name} - {item.description}
          </Description>
          <Quantity>Total quantity - {item.quantity}</Quantity>
        </div>
      </KitComponentWrapper>
    </>
  );
};

export default OrderKitComponent;

const KitComponentWrapper = styled.div`
  display: grid;
  grid-template-columns: 100px 1fr;
  column-gap: 20px;
  margin: 8px 0;
`;

const PhotoContainer = styled(Link)`
  border: 1px solid #ced4da;
  background-color: #fff;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
      border-color: ${PRIMARY};
    }
  }
`;

const Quantity = styled.div`
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
`;

const Description = styled(Link)`
  display: block;
  color: #444444;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  margin-bottom: 10px;

  &:hover {
    color: ${PRIMARY};
  }
`;
