import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router";

const ProgressItem = (props) => {
  const history = useHistory();
  const clickHandler = () => {
    if (props.linkActive) {
      history.push(props.to);
    }
  };

  return (
    <StyledComponent {...props} onClick={clickHandler}>
      <Line {...props} />
      <Icon {...props}>{props.step}</Icon>
      <Label {...props}>{props.label}</Label>
    </StyledComponent>
  );
};

export default ProgressItem;

const StyledComponent = styled.div`
  font-family: "Open Sans", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  &:hover {
    cursor: ${(props) => (props.linkActive ? "pointer" : null)};
  }
`;

const Icon = styled.div`
  font-weight: bolder;
  z-index: 2;
  background-color: white;
  padding: 2px 8px;
  border-style: solid;
  border-radius: 50%;
  border-width: 7px;
  border-color: ${(props) =>
    props.isActive
      ? "#ff5501"
      : props.isDisabled
      ? "#E4E4E4"
      : "rgb(40, 167, 69)"};
`;

const Line = styled.div`
  z-index: 1;
  background-color: ${(props) =>
    props.isActive
      ? "#ff5501"
      : props.isDisabled
      ? "#E4E4E4"
      : "rgb(40, 167, 69)"};
  position: absolute;
  width: 100%;
  height: 7px;
  top: 21px;
`;

const Label = styled.div`
  margin: 10px 0 20px;
  font-size: 18px;
  font-weight: 300;
  color: ${(props) =>
    props.isActive
      ? "#333333"
      : props.isDisabled
      ? "#E4E4E4"
      : "rgb(40, 167, 69)"};
`;
