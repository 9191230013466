import React from "react";
import { Modal } from "react-bootstrap";
import styled from "styled-components";
import { PRIMARY } from "../../../../vars/vars";
import { Form, Formik } from "formik";
import FormItem from "../../../molecules/FormItem/FormItem";
import { object, string } from "yup";
import { useSnackbar } from "react-simple-snackbar";
import axios from "axios";

const Enquiry = ({ show, part, handleClose }) => {
  const initialValues = {
    email: "",
    part: part.name,
  };

  const validationSchema = object().shape({
    email: string()
      .required("This is a required field.")
      .email("Please enter a valid email address (ex: johndoe@domain.com)."),
  });

  const onSubmit = ({ email, part }) => {
    const url =
      process.env.NODE_ENV === "production"
        ? "/scripts/enquiry.php"
        : "http://dev.pedders.eu/scripts/enquiry.php";

    const data = new FormData();
    data.append("email", email);
    data.append("part", part);

    axios({
      method: "POST",
      url,
      data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(() => {
        openSnackbar("Enquiry sent");
      })
      .finally(() => {
        handleClose();
      });
  };

  const props = {
    onSubmit,
    initialValues,
    validationSchema,
  };

  const [openSnackbar] = useSnackbar({
    position: "top-right",
  });

  return (
    <StyledModal show={show} onHide={handleClose}>
      <Formik {...props}>
        <Form>
          <Modal.Header closeButton>
            <Modal.Title>Send an enquiry</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Hi, I am interested in {part.description} {part.name}. Let me know
            if this is available.
            <FormItem placeholder={"Your email"} required name={"email"} />
          </Modal.Body>
          <Modal.Footer>
            <Button type={"submit"}>Send enquiry</Button>
          </Modal.Footer>
        </Form>
      </Formik>
    </StyledModal>
  );
};

export default Enquiry;

const StyledModal = styled(Modal)`
  font-family: "Open Sans", sans-serif;
`;

const Button = styled.button`
  display: block;
  padding: 10px 20px;
  background: #292b29;
  border: none;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 600;
  color: white;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.1s ease;
  white-space: nowrap;

  &:hover {
    background-color: ${PRIMARY};
  }
`;
