import React  from "react";
import FsLightbox from "fslightbox-react";

export const LightBox = ({ images, toggler }) => {

  return (
    <>
      <button
        style={{
          position: "absolute",
          top: "0px",
          right: "0px",
          border: "none",
          backgroundColor: "transparent",
          pointerEvents: "none",
        }}
      >
        <i
          className="fa fa-search"
          aria-hidden="true"
          style={{ color: "#666666" }}
        ></i>
      </button>
      <FsLightbox
        toggler={toggler}
        sources={images.map((obj) => obj.url)}
        type="image"
        style={{ border: "3px solid red" }}
      />
    </>
  );
};
