import React, { useEffect, useState } from "react";
import styled from "styled-components";
import styles from "./Buy.module.scss";
import DetailsInputButton from "../../atoms/DetailsInputButton/DetailsInputButton";
import plus from "./../../../assets/qty-plus.svg";
import minus from "./../../../assets/qty-minus.svg";
import DetailsAddButton from "../../atoms/DetailsAddButton/DetailsAddButton";
import { useAppContext } from "../../../context/AppContext";

const Buy = ({
  availability,
  record,
  addHandler,
  buttonText,
  enquiryHandler,
  changeQuantity,
  max,
  defaultStockLocation,
}) => {
  const [quantity, setQuantity] = useState(1);
  const [basketQuantity, setBasketQuantity] = useState(0);
  const { basket } = useAppContext();

  const handleChange = (e) => {
    setQuantity(e.target.value);
  };

  useEffect(() => {
    const found = basket?.transactionlines.find(
      (obj) => obj.item._id === record
    );
    setBasketQuantity(found ? found.quantity : 0);
  }, [basket]);

  const handleClick = (type) => {
    const found = basket?.transactionlines.find(
      (obj) => obj.item._id === record
    );
    setBasketQuantity(found ? found.quantity : 0);
    switch (type) {
      case "plus":
        setQuantity((prev) => (prev + 1 <= max ? prev + 1 : prev));
        break;
      case "minus":
        setQuantity((prev) => Math.max(1, prev - 1));
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    changeQuantity(quantity);
  }, [quantity]);

  return (
    <StyledBuy>
      {availability ? (
        <StyledInputArea>
          <DetailsInputButton
            image={minus}
            name={"minus"}
            clickHandler={handleClick}
          />
          <StyledInput
            className={styles.Input}
            type={"number"}
            value={quantity}
            disabled
            onChange={handleChange}
            min={"1"}
            max={max}
          />
          <StyledInputButtons>
            <DetailsInputButton
              image={plus}
              name={"plus"}
              clickHandler={handleClick}
            />
          </StyledInputButtons>
        </StyledInputArea>
      ) : null}
      <DetailsAddButton
        enquiryHandler={enquiryHandler}
        buttonText={buttonText}
        addHandler={addHandler}
        defaultStockLocation={defaultStockLocation}
        availability={availability}
        quantity={quantity}
        record={record}
        basketQuantity={basketQuantity}
      />
    </StyledBuy>
  );
};

export default Buy;

const StyledInputButtons = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledBuy = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledInputArea = styled.div`
  display: flex;
  align-items: center;
`;

const StyledInput = styled.input`
  width: 100%;
  height: 100%;
  min-height: 48px;
  max-height: 48px;
  padding: 0;
  border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  border-left: 0;
  border-right: 0;
  font-size: 20px;
  text-align: center;

  &:focus {
    outline: none;
  }
`;
