import React from "react";
import InStock from "../InStock/InStock";
import OutOfStock from "../OutOfStock/OutOfStock";
import AirFreight from "../AirFreight/AirFreight";
import InTransit from "../InTransit/InTransit";
import styled from "styled-components";

const Availability = ({ availability, quantity }) => {
  return (
    <StyledAvailability>
      {availability.location > 0 && quantity <= availability.location && (
        <InStock />
      )}
      {availability.location +
        availability.transit.productsInTransitInLessTreeWeeks >
        0 &&
        quantity > availability.location &&
        quantity <=
          availability.location +
            availability.transit.productsInTransitInLessTreeWeeks && (
          <InTransit date={availability.transit.deliveryDateUnderThreeWeeks} />
        )}
      {availability.location +
        availability.manufacturer +
        availability.transit.productsInTransitInLessTreeWeeks >
        0 &&
        quantity >
          availability.location +
            availability.transit.productsInTransitInLessTreeWeeks &&
        quantity <=
          availability.location +
            availability.manufacturer +
            availability.transit.productsInTransitInLessTreeWeeks && (
          <AirFreight />
        )}
      {availability.transit.productsInTransitInOverTreeWeeks > 0 &&
        quantity >
          availability.location +
            availability.transit.productsInTransitInLessTreeWeeks +
            availability.manufacturer &&
        quantity <=
          availability.location +
            availability.manufacturer +
            availability.transit.productsInTransitInLessTreeWeeks +
            availability.transit.productsInTransitInOverTreeWeeks && (
          <InTransit
            date={availability.transit.deliveryDateOverThreeWeeks}
            overThreeWeeks={true}
          />
        )}
      {quantity >
        availability.location +
          availability.manufacturer +
          availability.transit.productsInTransitInLessTreeWeeks +
          availability.transit.productsInTransitInOverTreeWeeks && (
        <OutOfStock />
      )}
    </StyledAvailability>
  );
};

export default Availability;

const StyledAvailability = styled.div`
  margin-top: 40px;
`;
