import React from "react";
import styled from "styled-components";
import styles from "./DetailsInputButton.module.scss";
import { PRIMARY } from "../../../vars/vars";

const DetailsInputButton = ({ clickHandler, image, name }) => (
  <StyledButton className={styles[name]} onClick={() => clickHandler(name)}>
    <Image src={image} />
  </StyledButton>
);

export default DetailsInputButton;

const Image = styled.img`
  width: 15px;
  height: 15px;
`;

const StyledButton = styled.button`
  width: 29px;
  height: 48px;
  background: #292b29;
  border: none;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.1s ease;

  &:hover {
    cursor: pointer;
    background-color: ${PRIMARY};
  }
`;
