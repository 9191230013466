import React from "react";
import styled from "styled-components";
import { Container } from "react-bootstrap";
import TopbarItem from "../../molecules/TopbarItem/TopbarItem";
import topbar from "../../../vars/topbar";
import { BREAKPOINT } from "../../../vars/vars";

const Topbar = () => (
  <StyledTopbar>
    <StyledContainer>
      <Links>
        {topbar.map(({ path, text, icon }) => (
          <TopbarItem key={text} icon={icon} path={path} text={text} />
        ))}
      </Links>
    </StyledContainer>
  </StyledTopbar>
);

export default Topbar;

const StyledTopbar = styled.section`
  background-color: #333333;
  height: 40px;
  color: #ffffff;
`;

const Links = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;

  @media screen and (min-width: ${BREAKPOINT}) {
    justify-content: flex-end;
  }
`;

const StyledContainer = styled(Container)`
  height: 100%;
`;
