import { object, string, boolean, ref } from "yup";
import debounce from "lodash/debounce";
import { updateBasket } from "../../../../axios/api";

const ASYNC_VALIDATION_TIMEOUT_IN_MS = 1000;

const validationFunction = async (value, resolve, cartId) => {
  try {
    const res = await updateBasket(cartId, "taxnumber", value);
    const isValid = res.data.shoppingcarts[0].shoppingcart.validtaxnumber;
    resolve(isValid);
  } catch (error) {
    resolve(false);
  }
};

const validationDebounced = debounce(
  validationFunction,
  ASYNC_VALIDATION_TIMEOUT_IN_MS
);

const defaultErrorMsg = "This is a required field.";

export const validationSchema = object().shape({
  firstName: string().required(defaultErrorMsg),
  lastName: string().required(defaultErrorMsg),
  companyName: string(),
  email: string()
    .required(defaultErrorMsg)
    .email("Please enter a valid email address (ex: johndoe@domain.com)."),
  emailSecond: string()
    .required(defaultErrorMsg)
    .email("Please enter a valid email address (ex: johndoe@domain.com).")
    .when("email", {
      is: (val) => val && val.length > 0,
      then: string().oneOf([ref("email")], "Both emails need to be the same!"),
    }),
  phone: string().required(defaultErrorMsg),
  billing: boolean(),
  company: string().when("billing", {
    is: true,
    then: string().required(defaultErrorMsg),
    otherwise: string(),
  }),
  billaddress: string().when("billing", {
    is: true,
    then: string().required(defaultErrorMsg),
    otherwise: string(),
  }),
  billaddress2: string(),
  billcity: string().when("billing", {
    is: true,
    then: string().required(defaultErrorMsg),
    otherwise: string(),
  }),
  billzip: string().when("billing", {
    is: true,
    then: string().required(defaultErrorMsg),
    otherwise: string(),
  }),
  billcountryname: string().when("billing", {
    is: true,
    then: string().required(defaultErrorMsg),
    otherwise: string(),
  }),
  streetAddress: string().required(defaultErrorMsg),
  streetAddress2: string().required(defaultErrorMsg),
  streetAddress3: string(),
  city: string().required(defaultErrorMsg),
  zip: string().required(defaultErrorMsg),
  country: string().required(defaultErrorMsg),
  termsConsent: boolean().oneOf([true], defaultErrorMsg),
  delivery: string().required(defaultErrorMsg),
  payment: string().required(defaultErrorMsg),
  isCompany: boolean(),
  vat: string().when("isCompany", {
    is: true,
    then: string()
      .required("This is a required field.")
      .test("Is VAT valid", "Invalid VAT number", function (value) {
        return new Promise((resolve) =>
          validationDebounced(value, resolve, this.parent.basketId)
        );
      }),
    otherwise: string(),
  }),
});
