import React from "react";

export default [
  {
    path: "/about",
    text: "About Us",
    icon: <i className="fa fa-heart" aria-hidden="true"></i>,
  },
  {
    path: "/contact",
    text: "Contact us",
    icon: <i className="fa fa-phone" aria-hidden="true"></i>,
  },
];
