import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { PRIMARY } from "../../../../vars/vars";
import { linksLeft, linksRight } from "./links";

const Vehicles = () => (
  <Styled>
    <div>
      {linksLeft.map(({ make, models }) => {
        return (
          <div key={make}>
            <StyledMake>{make}</StyledMake>
            {models.map(({ to, display }) => (
              <StyledLink
                key={display}
                to={to}
                className="gtm_nav_popular_vehicles"
              >
                {display}
              </StyledLink>
            ))}
          </div>
        );
      })}
    </div>
    <div>
      {linksRight.map(({ make, models }) => {
        return (
          <div key={make}>
            <StyledMake>{make}</StyledMake>
            {models.map(({ to, display }) => (
              <StyledLink
                key={display}
                to={to}
                className="gtm_nav_popular_vehicles"
              >
                {display}
              </StyledLink>
            ))}
          </div>
        );
      })}
    </div>
  </Styled>
);

export default Vehicles;

const StyledMake = styled.span`
  font-weight: bold;
  font-size: 12px;
  color: #666666;
  display: block;
  margin-bottom: 5px;
`;

const StyledLink = styled(Link)`
  display: block;
  margin-bottom: 5px;
  font-size: 12px;
  color: #444444;

  @media screen and (min-width: 992px) {
    padding-left: 15px;
  }

  &:hover {
    color: ${PRIMARY};
  }
`;

const Styled = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`;
